@use 'colors';
@use 'motion';

.brandsList,
.brandsList a,
.brandsListUnderline {
	transition: all motion.$duration-medium2 motion.$easing-calm;

	@media (prefers-reduced-motion: reduce) {
		transition: none;
	}
}

.brandsListUnderline {
	position: absolute;
	border-bottom: 1px solid transparent;
	border-color: colors.$content-accent;
	transform: none;
	inset-block-start: 39px;
	inset-inline-start: 0;
}
