@use 'colors';
@import 'mixins';

.footer {
	margin: 0 auto;
	background-color: colors.$background-default;
}

.form,
.legal {
	margin-right: auto;
	margin-left: auto;
	max-width: px2rem(350);
}

.title {
	text-align: center;
}
