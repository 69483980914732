@use 'colors';
@use 'texts';
@import 'mixins';
@import 'responsive/utils/mediaQueries.module';

.navigationLinkList {
	display: grid;
	list-style: none;
	margin: 0;
	padding: 0;

	@include onSmall {
		grid-template-columns: repeat(1, 1fr);
	}

	@include onMediumOrGreater {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 16px;
	}

	@include onLargeOrGreater {
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 32px;
	}
}
