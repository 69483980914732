@use 'colors';

$chatbot-size: 40px;

.container {
	position: fixed;
	inset-inline-end: 0;
	bottom: 100px;
	box-sizing: content-box;
	display: flex;
	justify-content: flex-end;
}

.open {
	z-index: 1; // added to be above header in the stacking context
}

.button {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: colors.$background-default-on-image;
	border: 1px solid colors.$border-strong;
	border-right-width: 0;
	cursor: pointer;
	height: $chatbot-size;
	padding: 0;
	width: $chatbot-size;

	&:dir(rtl) {
		border-left-width: 0;
		border-right-width: 1px;
	}
}

.app {
	z-index: 1; // added to be above containers in webviews
}
