@use 'motion';
@import 'responsive/utils/mediaQueries.module';

.topBar {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include onLessThanLarge {
		min-height: 60px;
	}

	@include onLargeOrGreater {
		height: 48px;
		padding-inline: 32px 0;
	}
}

.close {
	transition: opacity motion.$duration-medium2 motion.$easing-calm;
	opacity: 0;

	@media (prefers-reduced-motion: reduce) {
		transition: none;
	}
}
