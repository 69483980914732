@use 'colors';
@use 'texts';
@import 'mixins';
@import 'responsive/utils/mediaQueries.module';

.legal {
	display: flex;
	flex-direction: column;
	gap: 24px;

	@include onLargeOrGreater {
		gap: 4px;
	}
}

.contactInfo {
	display: flex;
	margin: 0;
	color: colors.$content-subtle;
}

.legalInfo {
	display: flex;
	justify-content: space-between;
	gap: 3.5rem;
	margin: 0;
	flex-flow: column wrap;

	@include onLargeOrGreater {
		flex-flow: row wrap;
		gap: 0.5rem;
	}
}

.reservedRights {
	margin: 0;
	display: flex;
	align-items: center;
}
