@use 'texts';
@import 'mixins';

.large {
	@extend %text-uppercase-m;
}

.small {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -25%);
	font-size: 8px;
	line-height: 8px;
	text-align: center;
}
