@import 'responsive/utils/mediaQueries.module';

.navigationLink {
	padding: 0;
	padding-block: 7px;

	@include onLessThanLarge {
		padding-block: 13px;
	}

	@include onLargeOrGreater {
		width: fit-content;
	}
}
