@use 'colors';
@import 'mixins';
@import 'responsive/utils/mediaQueries.module';

.header {
	position: sticky;
	top: 0;
	z-index: 1; // added to be above chatbot and main in the stacking context
	isolation: isolate;
	background-color: colors.$background-default;
	width: 100%;
}

.content {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: flex-start;
	padding: 6px 10px;
	height: px2rem(60);

	@include onLargeOrGreater {
		align-items: center;
		padding: 0 32px;
		height: px2rem(56);
	}
}

.user {
	display: flex;
	flex: 1;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
}
