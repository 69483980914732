@use 'colors';
@import 'fukku/Button/styles/states';
@import 'responsive/utils/mediaQueries.module';

.entryWrapper {
	display: block;
	border: none;
	margin: 0;
	text-align: start;
	text-decoration: none;

	@include onLargeOrGreater {
		padding-inline: 0;
		width: fit-content;
	}
}

.entry {
	@include onLessThanLarge {
		padding: 15px 14px;
	}

	@include onLargeOrGreater {
		padding: 12px 0;
	}
}
