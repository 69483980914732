@import 'responsive/utils/mediaQueries.module';

.brandsList {
	display: flex;
	flex-wrap: nowrap;
	list-style: none;
	margin: 0;
	padding: 0;
	align-content: center;

	@include onLessThanLarge {
		gap: 16px;
		padding-inline-start: 16px;
	}

	@include onLargeOrGreater {
		padding-inline-start: 0;
		width: fit-content;
	}
}
