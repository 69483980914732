@use 'texts';
@import 'responsive/utils/mediaQueries.module';
@import 'mixins';

.button {
	@extend %text-uppercase-m;

	border: none;
	background: none;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: 0.5rem;
	margin: 0 auto;
	flex-direction: column;

	@include onSmall {
		width: 100%;
	}

	@include onMedium {
		width: fit-content;
		flex-direction: row;
		align-items: center;
	}

	@include onLargeOrGreater {
		width: fit-content;
		flex-direction: row;
		align-items: center;
	}
}

.button:hover {
	cursor: pointer;
}

.buttonContent {
	display: flex;
	align-items: flex-start;
	gap: 0.5rem;
	width: 100%;
}

.countryTextMobile {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	column-gap: 0.5rem;
	align-items: flex-start;
	align-self: center;
}

.countryText {
	display: flex;
	column-gap: 0.5rem;
	align-items: center;
	height: 32px;
	text-align: start;
}

.icon {
	width: px2rem(16);
	height: px2rem(32);

	@include onLargeOrGreater {
		align-self: center;
	}
}
