@use 'colors';
@use 'motion';
@import 'fukku/Button/styles/states';

@mixin inspirational-decoration {
	position: relative;
	padding: 4px 0;

	&::after {
		position: absolute;
		inset-inline-start: 0;
		inset-block-end: 0;
		width: 100%;
		height: 1px;
		color: inherit;
		border-block-end: 1px solid;
		border-color: inherit;
		transition: border-color motion.$duration-short3 motion.$easing-calm;
		content: '';

		@media (prefers-reduced-motion: reduce) {
			transition: none;
		}
	}
}

.text {
	@include inspirational-decoration;

	&::after {
		border-color: transparent;
		transition-timing-function: motion.$easing-accent;

		@media (prefers-reduced-motion: reduce) {
			transition-timing-function: none;
		}
	}
}

.disabled {
	color: colors.$content-disabled;
}

a.buttonInspirational {
	display: block;
	text-decoration: none;
}

.buttonInspirational {
	text-align: start;
	width: 100%;
	transition: color motion.$duration-short3 motion.$easing-calm;
	border: unset;
	background: unset;

	&.active,
	&:active:not([aria-disabled='true']),
	&:hover:not([aria-disabled='true']) {
		.text {
			@include inspirational-decoration;
		}
	}

	@media (prefers-reduced-motion: reduce) {
		transition: none;
	}
}

// Variants: Default, Inverse, Promotion
.default {
	@include states(
		'color',
		colors.$content-accent,
		colors.$content-accent-hover,
		colors.$content-accent-active
	);
}

.inverse {
	@include states(
		'color',
		colors.$content-accent-on-inverse,
		colors.$content-accent-on-inverse-hover,
		colors.$content-accent-on-inverse-active
	);
}

.promotion {
	@include states(
		'color',
		colors.$content-accent-promotions,
		colors.$content-accent-promotions-hover,
		colors.$content-accent-promotions-active
	);

	&.unselected {
		color: colors.$content-accent-promotions-unselected;
	}
}

.default,
.inverse {
	&.unselected {
		color: colors.$content-accent-unselected;
	}
}
