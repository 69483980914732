@import 'responsive/utils/mediaQueries.module';

// SubBrands
.suBrandsWrapper {
	margin: 0;
	overflow-x: auto;

	@include onLessThanLarge {
		margin-block-end: 8px;
	}

	@include onLargeOrGreater {
		margin-block-end: 20px;
		margin-inline-end: 32px;
	}

	&::-webkit-scrollbar {
		height: 0;
	}
}

.subBrands {
	display: inline-flex;
	list-style: none;
	padding: 0;
	margin: 1px 16px;

	@include onLargeOrGreater {
		margin: 1px;
	}
}
