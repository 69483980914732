@use 'colors';
@use 'motion';
@use 'sass:string';

$padding-structure: 32;
$padding-content: 32;
$min-width: 416px;

.structure {
	position: fixed;
	inset-block-start: 0;
	inset-inline-start: 0;
	padding-block: 4px 16px;
	height: 100vh;
	background: colors.$background-default;
	background: linear-gradient(to left, transparent 50%, colors.$background-default 50%) right;
	background-size: 200%;
	white-space: nowrap;
	overflow: hidden;
	min-width: 0;
	width: 0;
	z-index: 2; // above header stacking context
}

.structure.open {
	animation: reveal-menu motion.$duration-medium2 motion.$easing-calm 1 none;
	width: var(--structure-width);
	background-position: left;
	transition: width motion.$duration-medium2 motion.$easing-calm;

	@media (prefers-reduced-motion: reduce) {
		animation: reveal-menu 0ms none;
		transition: none;
	}
}

.structure.close {
	transition:
		width motion.$duration-short2 motion.$easing-calm,
		background-position motion.$duration-short3,
		visibility motion.$duration-short3;
	transition-delay: 0ms, 300ms;
	animation-fill-mode: forwards;
	background-position: right;
	min-width: 0;
	width: 0;
	visibility: hidden;

	@media (prefers-reduced-motion: reduce) {
		transition: none;
	}
}

.content {
	margin-block-start: 24px;
	position: absolute;
	padding-inline: #{$padding-content}px 0;
}

@keyframes reveal-menu {
	from {
		width: 0;
	}
}

:export {
	/* stylelint-disable property-no-unknown */
	PADDING_STRUCTURE: $padding-structure;
	PADDING_CONTENT: $padding-content;
	MIN_WIDTH: $min-width;
	/* stylelint-enable property-no-unknown */
}
