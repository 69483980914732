@import 'mixins';

.genericError {
	margin-bottom: 40px;
	padding-right: 24px;
	padding-bottom: 24px;
	padding-left: 24px;
	height: px2rem(220);
	text-align: center;
}

.errorTitle {
	margin: 24px 0;
}

.errorText {
	margin: 0 0 32px;
}

.genericError .errorActionButton {
	margin-bottom: 16px;
}
