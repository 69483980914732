@use 'texts';
@use 'colors';
@use 'breakpoints';
@use 'outline';
@import 'mixins';
@import 'responsive/utils/mediaQueries.module';

.userIconContainer {
	height: 100%;
	position: relative;

	&:has(input:focus-visible) {
		outline: none;
	}

	:global(.k) &:has(input:focus-visible) {
		@extend %default-outline;
	}
}

.userIcon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;
	background-color: inherit;
	padding: 0;
	width: 100%;
	height: 100%;
	color: colors.$content-default;
}

.userIconMinWidth {
	min-width: var(--header-cart-min-width);
	align-items: flex-start;
}

.iconWrapper {
	display: flex;
	justify-content: center;
	align-items: baseline;
}

.icon {
	width: 100%;
	height: px2rem(22);
}

.label {
	@extend %text-uppercase-m;

	display: block;
	padding-top: 2px;

	@include onLessThanLarge {
		display: none;
		width: 100%;
		color: inherit;
		text-align: center;
		white-space: nowrap;
	}
}
