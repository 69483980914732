@use 'colors';
@import 'mixins';

.form {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 24px;
	margin-bottom: 24px;
}

.genericError {
	color: colors.$semantic-error;
}
