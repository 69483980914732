@use 'colors';
@use 'motion';

.button {
	height: 3rem;
	background-color: transparent;
	border: 0;
	margin-inline-end: 8px;
	padding: 0;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
}

.wrapperText {
	padding: 5px 8px;
	border: 1px solid transparent;
	color: colors.$content-accent-unselected;
	white-space: nowrap;
}

.selected {
	transition: border-color motion.$duration-short3 motion.$easing-calm;
	border-color: colors.$border-default;
	color: colors.$content-default;
	background: colors.$background-default;
}
