.expandableContent {
	position: relative;
}

.expander {
	cursor: pointer;
	color: inherit;
	border: none;
	background: none;
	padding: 0;
	text-wrap: nowrap;
	min-height: 24px;
	display: inline-block;
}

.contentToExpand {
	margin: 10px 0 0;
}
