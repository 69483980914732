@use './colors';

$lateral-padding: 6px; // 6px is the default value

%vertical-scrollbar {
	overflow: hidden auto;

	&::-webkit-scrollbar {
		width: $lateral-padding;
	}

	&::-webkit-scrollbar-thumb {
		background: linear-gradient(to right, colors.$background-default 50%, colors.$content-strong 50%) left;
	}

	&::-webkit-scrollbar-track {
		background-color: colors.$background-default;
	}
}
