@import 'mixins';

.brandLi {
	display: flex;
	align-items: center;
}

.brandEntry {
	min-width: auto;
	padding-inline: 0;
}

.accessibilityButton {
	height: px2rem(20);
	width: px2rem(20);
	border: 0;
	padding: 0;
	opacity: 0;
	background-color: transparent;

	&:focus-visible {
		opacity: 1;
	}
}
