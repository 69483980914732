@use 'colors';
@import 'mixins';

.breadcrumbs {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 60px;
	width: 100%;
	background: colors.$background-default;
}

.prevButton {
	height: 3rem;
	width: 3rem;
}

.textWrapper {
	height: fit-content;
	width: calc(100% - 6rem); // 3rem for the prev button and 3rem for the next button
}

.breadcrumbTitle {
	margin-inline-end: 8px;
}

.brand {
	padding-inline: 0 4px;
	color: colors.$content-subtle;
}
