// Duration
$duration-short1: 100ms;
$duration-short2: 200ms;
$duration-short3: 300ms;
$duration-medium1: 400ms;
$duration-medium2: 500ms;
$duration-medium3: 700ms;
$duration-large1: 900ms;
$duration-large2: 1100ms;
$duration-large3: 1300ms;
$duration-extra-large1: 1500ms;
$duration-extra-large2: 1800ms;

// Delay
$delay-short1: 100ms;
$delay-short2: 200ms;
$delay-medium1: 400ms;
$delay-medium2: 500ms;
$delay-large1: 800ms;
$delay-large2: 1000ms;

// Easing
$easing-calm: cubic-bezier(0.3, 0.85, 0.5, 1);
$easing-accent: cubic-bezier(0.5, 0, 0, 1);
