@use 'colors';
@use 'texts';

.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: 0;
}

.logoWithTest {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-decoration: none;
}

.logoLink {
	display: flex;
	align-items: flex-end;
	text-decoration: none;
}

.customDate {
	color: colors.$semantic-error;
}
