@use 'colors';
@use 'motion';

$min-width: 320px;

.structure {
	position: fixed;
	inset-block-start: 0;
	inset-inline-start: 0;
	background-color: colors.$background-default;
	overflow: hidden;
	height: 100dvh;
	width: 100vw;
	z-index: 2; // above header stacking context
}

.open {
	animation: open-menu motion.$duration-short3 motion.$easing-calm 1 none;
	min-width: $min-width;
	width: 100vw;

	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}
}

.close {
	animation: close-menu motion.$duration-short3 motion.$easing-calm 1 none;
	min-width: 0;
	visibility: hidden;
	width: 0;

	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}
}

.translateXOut {
	width: 200vw;
	animation: translate-x-out motion.$duration-medium1 motion.$easing-calm forwards;

	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}
}

.translateXIn {
	animation: translate-x-in motion.$duration-medium1 motion.$easing-calm forwards;

	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}
}

.content {
	margin-block-start: 8px;
	position: absolute;
	width: 100vw;
	inset-block-start: 60px;
	inset-inline-start: 0;
	z-index: 1; // above header stacking context
}

.hidden {
	transition: opacity motion.$duration-medium2 motion.$easing-calm;
	opacity: 0;

	@media (prefers-reduced-motion: reduce) {
		transition: none;
	}
}

@keyframes open-menu {
	from {
		width: 0;
		min-width: 0;
	}

	to {
		min-width: $min-width;
		width: 100%;
	}
}

@keyframes close-menu {
	from {
		visibility: visible;
		min-width: $min-width;
		width: 100%;
	}

	to {
		width: 0;
		min-width: 0;
		visibility: hidden;
	}
}

@keyframes translate-x-out {
	0% {
		inset-inline-start: 0%;
	}

	99% {
		inset-inline-start: -50%;
	}

	100% {
		inset-inline-start: -100%;
	}
}

@keyframes translate-x-in {
	0% {
		opacity: 0;
		inset-inline-start: -100%;
	}

	100% {
		opacity: 1;
	}
}
