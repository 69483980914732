@use 'motion';
@use 'colors';
@import 'mixins';

.interiorMenu {
	position: fixed;
	top: 0;
	height: 100dvh;
	width: 200%;
	background-color: colors.$background-default;
	inset-inline-start: -100%;
	padding-inline-start: 100%;
	opacity: 0;
}

.open {
	opacity: 1;
	animation:
		opacity motion.$duration-medium2 motion.$easing-calm,
		translate-x-in motion.$duration-medium1 motion.$easing-calm 1 none;

	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}
}

.close {
	padding-inline-start: 130%;
	opacity: 0;
	transition:
		opacity motion.$duration-short2 motion.$easing-calm,
		padding-inline-start motion.$duration-short3 motion.$easing-calm;

	@media (prefers-reduced-motion: reduce) {
		transition: none;
	}
}

@keyframes opacity {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes translate-x-in {
	from {
		inset-inline-start: -50%;
	}

	to {
		inset-inline-start: -100%;
	}
}
