@use 'colors';
@use 'outline';
@import 'mixins';

@mixin transition-input-focused {
	transition: top 0.15s ease-out;
}

@mixin transition-input-placeholder {
	transition:
		opacity,
		top 0.15s ease-out 0.05s;
}

@mixin transition-input-hint {
	transition: opacity 0.3s ease-in-out;
}

@mixin transition-input-error {
	transition:
		color,
		opacity 0.3s ease-in-out;
}

.inputWrapper {
	width: 100%;

	.box {
		display: flex;
		position: relative;
		flex-direction: column;
		box-sizing: border-box;
		border: 1px solid colors.$border-strong;
		padding: 3px 8px;
		height: px2rem(44);

		:global(.k) &:has(input:focus-visible) {
			@extend %default-outline-with-offset;
		}

		.label {
			position: relative;
			top: px2rem(10);
		}

		.focusedLabel {
			@include transition-input-focused;

			position: relative;
			top: px2rem(2);
		}

		.input {
			position: absolute;
			opacity: 0;
			box-sizing: border-box;
			outline: none;
			border: 0;
			color: colors.$content-default;
			background-color: colors.$background-default;
			padding: 0;
			height: 100%;

			&::placeholder {
				@include transition-input-placeholder;

				position: relative;
				top: px2rem(10);
				opacity: 0;
			}

			&::-webkit-date-and-time-value {
				text-align: left;
			}

			&::-webkit-calendar-picker-indicator:focus-visible {
				@extend %default-outline;
			}

			&::-webkit-datetime-edit-day-field:focus,
			&::-webkit-datetime-edit-month-field:focus,
			&::-webkit-datetime-edit-year-field:focus {
				color: colors.$content-default-on-inverse;
				background-color: colors.$background-accent;
				outline: none;
			}
		}

		.content {
			padding-right: 2rem;
		}

		.clearButton {
			position: absolute;
			top: 5px;
			right: 8px;
			cursor: pointer;
			border: none;
			background: none;
			padding: 0;
			font-size: 1.7rem;
		}

		.focusedInput {
			position: inherit;
			opacity: 1;

			&::placeholder {
				@include transition-input-placeholder;

				top: 0;
				opacity: 1;
				color: colors.$content-subtle;
			}
		}

		&.focused {
			border: 1px solid colors.$content-accent-active;
		}

		&.error {
			border: 1px solid colors.$semantic-error;

			@include transition-input-error;
		}

		&.disabled {
			border: 1px solid colors.$border-disabled;

			.input,
			.label {
				color: colors.$content-disabled;
			}
		}
	}

	.hint {
		display: none;
		opacity: 0;
		margin: 0;
		padding: 0 4px;
		color: colors.$content-strong;
	}

	.showHint {
		@include transition-input-hint;

		display: block;
		opacity: 1;
	}

	.errorMessage {
		display: none;
		opacity: 0;
		margin: 0;
		padding: 0 4px;
		color: colors.$semantic-error;
	}

	.showError {
		@include transition-input-error;

		display: block;
		opacity: 1;
	}
}
