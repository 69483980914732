@use 'colors';
@use 'motion';
@import 'responsive/utils/mediaQueries.module';

.menu {
	height: 100%;
	display: flex;

	@include onLargeOrGreater {
		align-items: center;
		box-shadow: none;
		height: auto;
		flex: 1 1;
	}
}

.menuBackdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: transparent;
	z-index: 1; // above menu stacking context
}

.menuBackdrop.open {
	visibility: visible;

	@include onLessThanLarge {
		animation:
			overlay-black motion.$duration-short3 motion.$easing-calm forwards,
			fixed-background 0ms motion.$easing-calm motion.$duration-short3 forwards;
	}

	@include onLargeOrGreater {
		animation: overlay-black motion.$duration-medium2 motion.$easing-calm forwards;
	}

	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}
}

.menuBackdrop.close {
	@include onLessThanLarge {
		animation: overlay-transparent motion.$duration-short3 motion.$easing-calm forwards;
	}

	@include onLargeOrGreater {
		background-color: transparent;
		visibility: hidden;
		animation: overlay-transparent motion.$duration-medium2 motion.$easing-accent forwards;
	}

	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}
}

@keyframes overlay-black {
	from {
		background-color: transparent;
		visibility: hidden;
	}

	to {
		background-color: colors.$background-overlay;
		visibility: visible;
	}
}

@keyframes overlay-transparent {
	from {
		background-color: colors.$background-overlay;
		visibility: visible;
	}

	to {
		background-color: transparent;
		visibility: hidden;
	}
}

@keyframes fixed-background {
	to {
		background-color: colors.$background-default;
	}
}
