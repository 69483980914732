@import 'mixins';
@import 'responsive/utils/mediaQueries.module';

.social {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 0;
	list-style: none;
	flex-wrap: wrap;

	@include onLargeOrGreater {
		max-width: px2rem(512);
		margin-inline: auto;
	}
}

.socialLink {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: px2rem(44);
	height: px2rem(44);
	line-height: 0;
	text-decoration: none;
}
