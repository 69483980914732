@use 'colors';
@use 'texts';
@import 'responsive/utils/mediaQueries.module';

.column {
	list-style: none;
	padding: 0 0 5rem;
	overflow-y: auto;
	height: 100%;

	@include onLessThanLarge {
		margin: 0 2px;
	}
}

.subLabel {
	@extend %text-body-s;

	color: colors.$content-subtle;
	margin: 0;
	margin-block-end: 4px;

	@include onLessThanLarge {
		margin-inline-start: 16px;
	}
}
