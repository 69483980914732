@use 'colors';
@use 'scrollbar';
@use 'motion';
@use './Columns.module.scss';
@import 'mixins';

.columnsWrapper {
	display: flex;

	ul:first-child {
		width: px2rem(213);
		min-width: px2rem(213);
		margin-inline-start: -3px;
		padding-inline-end: 8px;
	}

	ul:not(:first-child) li {
		animation: show-column motion.$duration-medium1 motion.$easing-calm;
		animation-fill-mode: forwards;
		visibility: hidden;
		opacity: 0;
		transition: all motion.$duration-medium1 motion.$easing-calm;
		width: 100%;

		@media (prefers-reduced-motion: reduce) {
			transition: none;
			animation: none;
			visibility: visible;
			opacity: 1;
		}
	}
}

.column {
	max-height: calc(var(--structure-height) - var(--column-start-top));
	overflow: visble auto;
	white-space: break-spaces;
	padding-inline-start: 3px;
	padding-block: 2px 1px;
	margin-block-start: -2px;

	@extend %vertical-scrollbar;

	& + & {
		width: px2rem(267);
		min-width: px2rem(267);
		margin-inline-start: 21px;
		padding-inline-end: 32px;
	}

	&.fullWidth {
		width: 100%;
	}
}

@keyframes show-column {
	50% {
		visibility: visible;
		opacity: 0.5;
	}

	100% {
		visibility: visible;
		opacity: 1;
	}
}
