@import 'responsive/utils/mediaQueries.module';

.legalLinks {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin: 0;
	padding: 0;
	list-style-type: none;
	text-align: center;

	@include onLargeOrGreater {
		flex-direction: row;
		gap: 24px;
	}
}

.legalLink {
	padding: 0;
	padding-block: 7px;
	text-align: start;

	@include onLessThanLarge {
		padding-block: 13px;
	}

	@include onLargeOrGreater {
		width: fit-content;
	}
}
